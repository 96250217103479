<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="退款申请"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="退款金额" v-bind="validateInfos.refundAmount">
        <a-input
          v-model:value="refundVo.refundAmount"
          placeholder="退款金额不能大于订单金额"
          allow-clear
          ><template #addonAfter> 元 </template>
        </a-input>
        <span style="font-size: 11px; color: gray"
          >备注：退款金额不能大于订单金额</span
        >
      </a-form-item>
      <a-form-item label="备注" v-bind="validateInfos.reason">
        <a-textarea
          :row="2"
          v-model:value="refundVo.reason"
          allow-clear
        ></a-textarea>
      </a-form-item>
    </a-form>

    <a-descriptions bordered size="small" :column="1">
      <a-descriptions-item label="订单号">{{
        refundRecordVo.payOrderId
      }}</a-descriptions-item>
      <a-descriptions-item label="支付状态">
        <a-tag v-if="refundRecordVo.payState == 0" color="red">待支付</a-tag>
        <a-tag v-if="refundRecordVo.payState == 2" color="success"
          >支付完成</a-tag
        >
        <a-tag v-if="refundRecordVo.payState == 3" color="orange">待结算</a-tag>
      </a-descriptions-item>
      <a-descriptions-item label="实付金额">{{
        refundRecordVo.paidAmount + "元"
      }}</a-descriptions-item>
      <a-descriptions-item label="支付时间">{{
        refundRecordVo.payTime
      }}</a-descriptions-item>
      <a-descriptions-item label="退款人昵称">{{
        refundRecordVo.nickName
      }}</a-descriptions-item>
      <a-descriptions-item label="退款人电话">{{
        refundRecordVo.phone
      }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useRefundRecord from "@/hooks/Info/useRefundRecord";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { paidDetail } from "@/api/info/payRecordApi";
import { addRefund } from "@/api/info/refundRecordApi";

const useForm = Form.useForm;
export default {
  name: "Refund",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { refundRecordVo, refundVo, money, moneyZ } = useRefundRecord();
    const { refundVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(refundVo, refundVoRules);
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的退款申请?");
        if (action) {
          disabled.value = true;
          await showLoading();

          let obj = {
            payOrderId: refundVo.payOrderId,
            refundAmount: moneyZ(refundVo.refundAmount),
            reason: refundVo.reason,
          };
          try {
            await addRefund(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      try {
        let { data } = await paidDetail(props.id);
        refundRecordVo.payOrderId = data.payOrderId;
        refundRecordVo.payState = data.payState;
        refundRecordVo.shouldPayAmount = money(data.shouldPayAmount);
        refundRecordVo.paidAmount = money(data.paidAmount);
        refundRecordVo.payTime = data.payTime;
        refundRecordVo.phone = data.phone;
        refundVo.payOrderId = data.payOrderId;

        refundVo.refundAmount = money(data.paidAmount);
      } catch (e) {
        showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      refundRecordVo,
      refundVo,
      validateInfos,
      money,
      moneyZ,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
